import { Link, Outlet, useMatches } from "@remix-run/react";
import dogIconBlack from "~/assets/dogIconBlack.svg";
export const BaseFrame = () => {
  const matches = useMatches();
  const isRegister = matches.some(
    (match) => match.pathname === "/sign-in/register"
  );
  return (
    <>
      <div className="h-[100vh] bg-gray-50">
        <div className="h-full">
          <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <img
                className="-my-6 mx-auto h-16 w-auto"
                src={dogIconBlack}
                alt="Workflow"
              />
              <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                {isRegister
                  ? "Create a new account"
                  : "Sign in to your account"}
              </h2>
              <p className="mt-2 text-center text-sm text-gray-600">
                Or{" "}
                {isRegister ? (
                  <Link
                    prefetch="render"
                    to="/sign-in"
                    className="font-medium text-primary-600 hover:text-primary-500"
                  >
                    login to your existing account
                  </Link>
                ) : (
                  <Link
                    prefetch="render"
                    to="register"
                    className="font-medium text-primary-600 hover:text-primary-500"
                  >
                    register a new account
                  </Link>
                )}
              </p>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
              <div className="bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BaseFrame;
